// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("gritter/js/jquery.gritter.js")
require("packs/orders")

import * as bootstrap from 'bootstrap'
import "../stylesheets/application"
import "../stylesheets/styles.css"
import "../stylesheets/gritter.css"
import "../stylesheets/navbar.css"
import "../stylesheets/select2.min.css"
import "@fortawesome/fontawesome-free/css/all"

document.addEventListener("turbolinks:load", () =>{

	$('[data-toggle="tooltip"]').tooltip()
	var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
	var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  	return new bootstrap.Popover(popoverTriggerEl)
	})

	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
	  return new bootstrap.Tooltip(tooltipTriggerEl)
	})	

	var toastElList = [].slice.call(document.querySelectorAll('.toast'))
	var toastList = toastElList.map(function (toastEl) {
	  return new bootstrap.Toast(toastEl, option)
	})

	$(".content-search").select2();

	$(".modal-gastos").select2({ dropdownParent: $("#modalGastos") });

})