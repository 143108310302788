document.addEventListener("turbolinks:load", () =>{

	$(".editarGasto").on("click", function () {
	    $('#provider_id').select2().trigger('change');
	});

	// Modal para agregar nuevo pedido
	var modalPedido = document.getElementById('modalPedido')
	if (modalPedido){
		modalPedido.addEventListener('show.bs.modal', function (event) {

			var button = event.relatedTarget
			var idOrder = button.getAttribute('data-bs-idOrder');
			var amount =  button.getAttribute('data-bs-amount');
			var idPaymentMethod = button.getAttribute('data-bs-idPaymentMethod');

	 		$(".type_alert1").hide();
			$(".type_alert2").hide();

		  	const element = document.querySelector("#ingreso-pedido");

		if(idPaymentMethod == null){
			if(idOrder == null){
				element.addEventListener("submit", (event) => {
					$("#guardar_pedido").attr("disabled", true);
				});
			  element.addEventListener("ajax:success", (event) => {
			  	$("#guardar_pedido").attr("disabled", false);
				$(".type_alert1").show();
				$(".type_alert2").show();

			    const [_data, _status, xhr] = event.detail;
			    document.getElementById('amount_order').value = "";
			    $("#tbl_orders").append('<tr><td>'+_data.order_number+'</td><td>'+_data.amount+'</td></tr>');
			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".type_alert2").addClass(_data.alert_type2);
			    $(".alert_message1").text(_data.alert_message1);
			    $(".alert_message2").text(_data.alert_message2);
			  });
			  element.addEventListener("ajax:error", (event) => {
			  	$(".type_alert1").show();
			  	const [_data, _status, xhr] = event.detail;
			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".alert_message1").text(_data.alert_message1);
			  });
			}
			else{
				$("#ingreso-pedido").attr('action', '/update_order');
				$("#guardar_pedido").text("Actualizar")
				document.getElementById('amount_order').value = amount;
				document.getElementById('idOrder').value = idOrder;

			  element.addEventListener("submit", (event) => {
					$("#guardar_pedido").attr("disabled", true);
				});


			  element.addEventListener("ajax:success", (event) => {
				$(".type_alert1").show();
				$("#guardar_pedido").attr("disabled", false);
			    const [_data, _status, xhr] = event.detail;
	    	    $('#idOrder'+_data.idOrder).each(function() {
				  $(this).html(_data.amount);
				});

			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".alert_message1").text(_data.alert_message1);
			  });
			  element.addEventListener("ajax:error", (event) => {
			  	$(".type_alert1").show();
			  	const [_data, _status, xhr] = event.detail;
			  	$("#guardar_pedido").attr("disabled", true);
			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".alert_message1").text(_data.alert_message1);
			  });
			}
		}
		else{
			var method_name = button.getAttribute('data-bs-method-name');
			$(".modal-title").html("Distribución de Ingresos")
			$("#ingreso-pedido > div > div > label").html("Ingrese el monto a actualizar para: "+ method_name);
			$("#ingreso-pedido").attr('action', '/add_payment_distribution');
			$("#guardar_pedido").text("Actualizar")
			document.getElementById('amount_order').value = amount;
			document.getElementById('idPaymentMethod').value = idPaymentMethod;

		  element.addEventListener("submit", (event) => {
				$("#guardar_pedido").attr("disabled", true);
			});

		  element.addEventListener("ajax:success", (event) => {
		  	$("#guardar_pedido").attr("disabled", false);
			$(".type_alert1").show();
			document.getElementById('guardar_pedido').disabled = true;

		    const [_data, _status, xhr] = event.detail;
		    $('#idPaymentMethod'+_data.id).each(function() {
			  $(this).html(_data.amount);
			});

		    $(".type_alert1").addClass(_data.alert_type1);
		    $(".alert_message1").text(_data.alert_message1);
		  });
		  element.addEventListener("ajax:error", (event) => {
		  	$(".type_alert1").show();
		  	const [_data, _status, xhr] = event.detail;
		  	$("#guardar_pedido").attr("disabled", true);
		    $(".type_alert1").addClass(_data.alert_type1);
		    $(".alert_message1").text(_data.alert_message1);
		  });
		}
		})

		modalPedido.addEventListener('hide.bs.modal', function (event) {
			window.location.reload();
		})
	}

	// Modal para agregar nuevo gasto ocurrido en la venta
	var modalGastos = document.getElementById('modalGastos')
	if(modalGastos){
		modalGastos.addEventListener('show.bs.modal', function (event) {
			var button = event.relatedTarget
			var idExpense = button.getAttribute('data-bs-idExpense');
			var amount =  button.getAttribute('data-bs-amount');
			var detail = button.getAttribute('data-bs-detail');
			var expense_by_provider_id = button.getAttribute('data-bs-provider_id');

	 		$(".type_alert1").hide();
			$(".type_alert2").hide();

			if(idExpense == null){
			  const element = document.querySelector("#ingreso-gasto");

			  element.addEventListener("submit", (event) => {
					$("#guardar_pedido").attr("disabled", true);
				});

			  element.addEventListener("ajax:success", (event) => {
			  	$("#guardar_pedido").attr("disabled", false);
				$(".type_alert1").show();
				$(".type_alert2").show();

			    const [_data, _status, xhr] = event.detail;
			    document.getElementById('guardar_gasto').disabled = false;
			    document.getElementById('amount_cost').value = "";
			    document.getElementById('detail_cost').value = "";
			    $("#tbl_expenses").append('<tr><td>'+_data.expense_number+'</td><td>'+_data.detail+'</td><td>'+_data.amount+'</td></tr>');
			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".type_alert2").addClass(_data.alert_type2);
			    $(".alert_message1").text(_data.alert_message1);
			    $(".alert_message2").text(_data.alert_message2);
			  });
			  element.addEventListener("ajax:error", (event) => {
			  	$(".type_alert1").show();
			  	const [_data, _status, xhr] = event.detail;
			  	$("#guardar_pedido").attr("disabled", true);
			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".alert_message1").text(_data.alert_message1);
			  });
			}
			else{
				$("#ingreso-gasto").attr('action', '/update_expense');
				$("#guardar_gasto").text("Actualizar")
				document.getElementById('amount_cost').value = amount;
				document.getElementById('idExpense').value = idExpense;
				document.getElementById('detail_cost').value = detail;
				document.getElementById('expense_by_provider').value = expense_by_provider_id;
				$('#expense_by_provider').trigger('change');

			  const element = document.querySelector("#ingreso-gasto");

			  element.addEventListener("submit", (event) => {
					$("#guardar_pedido").attr("disabled", true);
				});

			  element.addEventListener("ajax:success", (event) => {
				$(".type_alert1").show();
				$("#guardar_gasto").attr("disabled", false);

			    const [_data, _status, xhr] = event.detail;
	    	    $('#idExpense'+_data.idExpense).each(function() {
				  $(this).html(_data.amount);
				});
	    	    $('#idDetailExpense'+_data.idExpense).each(function() {
				  $(this).html(_data.detail);
				});
			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".alert_message1").text(_data.alert_message1);
			  });
			  element.addEventListener("ajax:error", (event) => {
			  	$(".type_alert1").show();
			  	const [_data, _status, xhr] = event.detail;
			  	$("#guardar_gasto").attr("disabled", true);
			    $(".type_alert1").addClass(_data.alert_type1);
			    $(".alert_message1").text(_data.alert_message1);
			  });
			}
		})

		modalGastos.addEventListener('hide.bs.modal', function (event) {
			window.location.reload();
		})
	}

	// Modal para agregar nuevo gasto ocurrido en la venta
	var modalMediosPago = document.getElementById('modalMediosPago')
	if(modalMediosPago){
		modalMediosPago.addEventListener('show.bs.modal', function (event) {
		  $(".type_alert1").hide();
		  $(".type_alert2").hide();
		  const element = document.querySelector("#ingreso-metodo-pago");

		  element.addEventListener("submit", (event) => {
				$("#guardar_pedido").attr("disabled", true);
			});

		  element.addEventListener("ajax:success", (event) => {
			$(".type_alert1").show();
			$(".type_alert2").show();

		  	$("#guardar_pedido").attr("disabled", false);
		    const [_data, _status, xhr] = event.detail;
		    document.getElementById('guardar_metodo_pago').disabled = false;
		    document.getElementById('amount_payment').value = "";
		    if($('#idPaymentMethod'+_data.id).length){
				$('#idPaymentMethod'+_data.id).each(function() {
				  $(this).html(_data.amount);
				});
			}else{
			    $("#tbl_payment_methods").append('<tr><td>'+_data.payment_method+'</td><td>'+_data.amount+'</td></tr>');
			}

		    $(".type_alert1").addClass(_data.alert_type1);
		    $(".type_alert2").addClass(_data.alert_type2);
		    $(".alert_message1").text(_data.alert_message1);
		    $(".alert_message2").text(_data.alert_message2);
		  });
		  element.addEventListener("ajax:error", (event) => {
		  	$(".type_alert1").show();
		  	const [_data, _status, xhr] = event.detail;
		  	$("#guardar_pedido").attr("disabled", true);
		    $(".type_alert1").addClass(_data.alert_type1);
		    $(".alert_message1").text(_data.alert_message1);
		  });
		})

		modalMediosPago.addEventListener('hide.bs.modal', function (event) {
			window.location.reload();
		})
	}

})
